.input-group {
    position: relative;
   }
   
   .custominput {
    border: 0.5px solid #C5C5C5;
    border-radius: 0.5rem;
    
  
    padding: 1rem;
    font-size: 1rem;
    color: "black";
    transition: border 150ms cubic-bezier(0.4,0,0.2,1);
   }
   
   .user-label {
    position: absolute;
    left: 15px;
    color: grey;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4,0,0.2,1);
   }
   
   .custominput:focus, custominput:valid {
    outline: none;
    border: 0.5px solid #C5C5C5;
   }
   
   .custominput:focus ~ label, input:valid ~ label {
    transform: translateY(-50%) scale(0.8);
    background-color: #ffffff;
    padding: 0 .2em;
    color: grey;
   }


   .custominput{
    width:70px;
    height:10px;
    margin-left:10px
   }